.Footer-container {
  position: relative;
}
.Footer-container > hr {
  border: 1px solid var(--lightgray);
}
.footer {
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  align-items: center;
  justify-content: center;
  height: 20rem;
}
.social-links {
  display: flex;
  gap: 4rem;
}
.social-links > img {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
}
.logo-f > img {
  width: 10rem;
}
.blur-footer-1 {
  bottom: 0;
  right: 14%;
  width: 26rem;
  height: 12rem;
  filter: blur(280px);
  background: rgb(255, 255, 255);
}
.blur-footer-2 {
  bottom: 0;
  left: 15%;
  width: 26rem;
  height: 12rem;
  filter: blur(280px);
  background: rgb(245, 235, 235);
}
.cright{
  font-size: 6px;
  color: rgb(145, 137, 137);
}