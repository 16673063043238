/* make variables */
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --orange: rgb(100, 93, 93);
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(#d61e09, #000000);
  --appColor: #3c3f45;
}

.App {
  mix-blend-mode: overlay;
  background-color: rgb(0, 0, 0);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}
::-webkit-scrollbar {
  display: none;
}
.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: white;
}
.btn {
  background-color: #fff;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms alt;
}
.btn:hover {
  cursor: pointer;
}

.blur {
  background: rgb(245, 243, 243);
  position: absolute;
  border-radius: 50%;
  filter: blur(250px);
  z-index: -9;
}
